<template>
<div>

    <!-- <v-dialog v-if="processing" v-model="processing" :persistent="messageProcessing.length==0" width="400">
        <v-card color="primary" dark>
            <v-card-text v-if="messageProcessing.length == 0">
                Por favor espere ...
                <v-progress-linear indeterminate color="white" class="mb-0 mt-2"></v-progress-linear>
            </v-card-text>
            <v-card-text v-else>
                <div class="text--white pt-4">
                    Error.. <br />
                    {{ messageProcessing }}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog> -->
    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>

    <!--CIRCUITO DE APRBACIONES MODAL-->
    <div v-if="dialogCircuit">
        <v-dialog persistent v-model="dialogCircuit" width="70%">

            <s-toolbar color="#5175AF" dark label="Circuitos Asociados a la Operación" @close="dialogCircuit = false" close></s-toolbar>
            <v-card>
                <v-container>
                    <v-row>
                        <v-col cols="12" :lg="this.filterLevels.AccID != null ? 7: 12" :md="this.filterLevels.AccID != null ? 7: 12">
                            <v-data-table dense item-key="AccID" :items="circuits" :items-per-page="-1" height="220" :headers="[
                    { value: 'AccID', text: 'ID', width: 30 , align: 'center' },
                    { value: 'AccName', text: 'Circuito' },
                  ]" @dblclick:row="createCircuit" v-model="selectedCircuit" @click:row="rowClickCircuit" />
                        </v-col>
                        <v-col cols="12" lg="5" md="5">
                            <s-crud class="elevation-0 table table-hover table-condensed" height="220" noFull v-if="this.filterLevels.AccID != null" no-toolbar :config="configLevels" :filter="filterLevels" title="Niveles" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>

    <div>
        <s-toolbar close :save="value == null || orderLocal.OrdStatus == 3" @save="save()" :label="'Despacho de Producto'" @close="$emit('onClose')" :transactionApproved="transactionApproved">

            <v-divider vertical></v-divider>

        </s-toolbar>
        <v-card>
            <v-col>
                <v-row>
                    <v-col lg="" cols="12">
                        <v-card elevation="0" height="auto">
                            <!-- <v-col class="">

                                <v-row>

                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <s-select-definition style="font-family: Calibri" :disabled="!isEditable" :def="1129" label="Moneda" v-model="orderLocal.TypeCurrency" />
                                    </v-col>
                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <s-select-definition style="font-family: Calibri" :disabled="!isEditable" :def="1159" label="Forma de Pago" v-model="orderLocal.TypePayment" />
                                    </v-col>
                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <s-date label="Fecha" style="font-family: Calibri" v-model="dateOrder" :disabled="!isEditable"></s-date>
                                    </v-col>

                                </v-row>
                                <v-divider style="margin-top:20px" horizontal></v-divider>

                            </v-col> -->
                        </v-card>
                    </v-col>

                    <v-divider vertical></v-divider>

                    <v-col lg="12" cols="12">
                        <v-col class="pr-0 pl-0">

                            <v-row>
                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">Datos generales</v-card-title>
                                </v-card>
                                <v-col lg="3">
                                    <s-date label="Fecha entrega" style="font-family: Calibri" v-model="orderLocal.DspDate"></s-date>

                                </v-col>
                                <v-col lg="3">
                                    <s-select label="Usuario" placeholder="Digite Usuario" autofocus autocomplete clearable item-text="NtpFullName" v-model="orderLocal.UsrCreateID" item-value="UsrID" :items="itemsUsers"></s-select>
                                </v-col>

                            </v-row>
                        </v-col>
                        <v-row>
                            <v-col>
                                <div class="mt-2">
                                    <s-toolbar label="Detalle" add @add="addItem()" remove @removed="removeItem()">
                                        <template v-slot:options>
                                            <div class="mt-1">
                                                <s-select :outlined="false" :items="[
                              { value: 1, text: 'Articulo' },
                            ]" v-model="orderLocal.TypeOrder"></s-select>
                                            </div>
                                        </template>
                                    </s-toolbar>
                                </div>

                                <v-card outlined>

                                    <v-data-table class="elevation-0 table table-hover table-condensed" dense @click:row="rowClick" :headers="headersSAP" :items-per-page="-1" v-model="selectedSAP" :items="itemsSAP" item-key="Line" :height="$vuetify.breakpoint.xs ? 'auto' : '200'" hide-default-footer>
                                        <template v-slot:item.OdsDescription="props">
                                            <div>
                                                <c-article label="" return-object @input="inputArticle($event, props.item)" v-model="props.item.ArticleObj"></c-article>
                                            </div>
                                        </template>

                                        <template v-slot:item.OdsQuantity="props">
                                            <s-text :outlined="false" style="width: 80px" decimal v-model="props.item.OdsQuantity">
                                                Cantidad
                                            </s-text>
                                        </template>

                                        <template v-slot:item.OdsObservation="props">
                                            <s-text label="" v-model="props.item.OdsObservation" :maxlength="100" />
                                        </template>

                                        

                                    </v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-col style="padding-top: 24px">
                            <v-row>
                                <v-col lg="12" cols="12">
                                    <v-row>
                                        <v-col class="s-col-text">
                                            <s-textarea label="Observaciones Generales" v-model="orderLocal.DspObservation" ref="OrdObservation" :maxlength="100" />
                                            <span style="font-size: 9px; color:red">Max {{'100'}} Caracteres - {{orderLocal.DspObservation !== undefined && orderLocal.DspObservation !== null ? orderLocal.DspObservation.length : ''}}</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-col>

                </v-row>
            </v-col>
        </v-card>
    </div>
</div>
</template>

<script>
//Services
import _sOutputProduct from "@/services/Logistics/LgsOutputProductService";
import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";
import _sPermit from "@/services/Logistics/PermitService";
//Components
import cArticle from "@/components/Logistics/cArticleSearch.vue";

export default {
    components: {
        cArticle
    },
    props: {
        order: {},
        value: null
    },
    data: () => ({
        processing: false,
        messageProcessing: "",

        item: {
            OddDescription: "",
            ArtCode: "",
        },

        itemsUsers: [],

        selectedrow: [],
        circuits: [],
        selectedCircuit: [],
        filterLevels: {},
        configLevels: {
            model: {
                AccID: "int",
                AlvID: "ID",
                SecStatus: "boolean",
                PstID: "int",
                PstName: "string",
            },
            service: _sApprovalLevel,
            headers: [{
                    text: "Cargo",
                    value: "PstName"
                },
                {
                    text: "Nivel",
                    value: "TypeLevelText"
                },
            ],
        },
        optionApproved: false,
        selectedSAP: [],
        transactionApproved: null,
        dialogCircuit: false,
        orderLocal: {
            TypeOrder: 1
        },
        dialogAdd: false,
        itemsSAP: [],
        itemsRemove: [],
        headersSAP: [{
                text: "Descripción",
                value: "OdsDescription",
                sortable: false,
                width: 540,
            },

            {
                text: "Stock",
                value: "ArtStock",
                sortable: false,
                width: 50
            },

            {
                text: "Cant",
                value: "OdsQuantity",
                sortable: false,
                width: 50
            },

            {
                text: "Und",
                value: "UnitMeasurement",
                sortable: false,
                width: 50
            },

            {
                text: "Comentario",
                value: "OdsObservation",
                sortable: false,
                width: 400,
            },
        ],

    }),

    created() {
        _sPermit.users(this.$fun.getUserID()).then((r) => {
            this.itemsUsers = r.data;
        });
    },

    methods: {

        //Seleccionar Fila
        rowClick: function (item, row) {
            this.selectedSAP = [item];
        },

        /***************************************** Fin Item Detalle ***************************************************************** */
        //Agregar Nuevo Item
        addItem() {
            this.item.Line = this.itemsSAP.length + 1;
            this.itemsSAP.push({
                ...this.item
            });
        },

        //Remover Item
        removeItem() {
            if (this.selectedSAP.length == 1) {
                if (this.itemsSAP.length <= 1) {
                    this.$fun.alert(
                        "No se permite eliminar el ultimo item de la orden",
                        "warning"
                    );
                    return;
                }
                this.itemsSAP = this.itemsSAP.filter(
                    (x) => x.Line != this.selectedSAP[0].Line
                );
                this.selectedSAP[0].OddStatus = 0;

                if (this.selectedSAP[0].OddID > 0)
                    this.itemsRemove.push(this.selectedSAP[0]);
                //this.selectedSAP[0]=this.itemsSAP[0];
            }
        },

        inputArticle(article, item) {

            item.UnitMeasurement = article.UnitOfMeasuarement;
            item.ArtCode = article.ArtCode;
            item.OdsDescription = article.ArtName;
            item.ArtStock = article.ArtStock;

        },

        save() {

            if (this.itemsSAP.length < 1) {
                this.$fun.alert("Falta Items", "warning");
                return;
            }

            if (this.orderLocal.DspDate < this.$fun.getDate()) {
                this.$fun.alert("Fecha no puede ser menor a fecha actual", "warning");
                return
            }

            if (this.orderLocal.DspObservation !== undefined && this.orderLocal.DspObservation.length > 100) {
                this.$refs.OrdObservation.focus()
                this.$fun.alert("Maximo 100 Caracteres", "warning");
                return;
            }

            for (let i = 0; i < this.itemsSAP.length; i++) {

                if (this.itemsSAP[i].OdsDescription) {
                    if (this.itemsSAP[i].OdsDescription.trim().length < 1) {
                        this.$fun.alert("Falta Descripción", "warning");
                        return;
                    }
                } else {
                    this.$fun.alert("Falta Descripción", "warning");
                    return;
                }

                if (
                    this.itemsSAP[i].OdsQuantity == null ||
                    this.itemsSAP[i].OdsQuantity == 0
                ) {
                    this.$fun.alert(
                        "Cantidad inválido, " + this.itemsSAP[i].OdsQuantity,
                        "warning"
                    );
                    return;
                }

                /* if (
                    this.itemsSAP[i].OdsQuantity > this.itemsSAP[i].ArtStock
                ) {
                    this.$fun.alert(
                        "Cantidad no disponible, Stock:  " + this.itemsSAP[i].ArtStock+ ", "+ this.itemsSAP[i].OdsDescription,
                        "warning"
                    );
                    return;
                } */

                if (this.itemsSAP[i].OdsDescription !== undefined && this.itemsSAP[i].OdsDescription.length > 100) {
                    this.$fun.alert(
                        "Maximo 100 caracteres, " + this.itemsSAP[i].OdsDescription,
                        "warning"
                    );
                    return;
                }

                if (this.itemsSAP[i].OdsObservation !== undefined && this.itemsSAP[i].OdsObservation.length > 100) {
                    this.$fun.alert(
                        "Maximo 100 caracteres, " + this.itemsSAP[i].OdsObservation,
                        "warning"
                    );
                    return;
                }

            }
            this.orderLocal.DspStatus = 4
            this.orderLocal.UsrID = this.orderLocal.UsrCreateID
            this.orderLocal.Details = this.itemsSAP;
            this.orderLocal.UsrCreateID = this.$fun.getUserID()

            console.log("orderLocal", this.orderLocal);

            this.$fun.alert("Seguro de guardar?", "question", false).then((r) => {
                if (r.value) {
                    this.messageProcessing = ""
                    this.processing = true
                    this.orderLocal.Details.push(...this.itemsRemove);
                    _sOutputProduct
                        .save(this.orderLocal, this.$fun.getUserID(), 0)
                        .then((r) => {

                                if (r.data.length > 1) {
                                    this.circuits = r.data;
                                    this.dialogCircuit = true;
                                } else {
                                    this.processing = false
                                    this.$fun.alert("Guardado Correctamente", "success");
                                    this.$emit("onClose");
                                }
                            },
                            (e) => {
                                this.messageProcessing = e.response.data.Message;
                                this.processing = true;
                            });
                }
            });
        },

        //********************************** CIRCUITOS DE APROBACION************************************************* */
        rowClickCircuit: function (item, row) {
            this.selectedCircuit = [item];
            this.filterLevels = {
                AccID: item.AccID
            };
        },

        createCircuit(item) {
            if (this.itemsSAP.length < 1) {
                this.$fun.alert("No se ha registrado ningún detalle", "warning");
                return;
            }

            this.$fun
                .alert("Guardar con " + this.selectedCircuit[0].AccName, "question")
                .then((r) => {
                    if (r.value) {
                        this.messageProcessing = ""
                        this.processing = true

                        this.orderLocal.UsrUpdateID = this.selectedCircuit[0].AccID;
                        this.dialogCircuit = false;
                        this.orderLocal.Details = this.itemsSAP;
                        _sOutputProduct
                            .save(
                                this.orderLocal,
                                this.$fun.getUserID(),
                                this.selectedCircuit[0].AccID
                            )
                            .then((r) => {
                                    this.processing = false
                                    this.$emit("onClose");
                                    this.$fun.alert("Agregado Correctamente", "success");
                                    this.$emit("close");
                                },
                                (e) => {
                                    this.messageProcessing = e.response.data.Message;
                                    this.processing = true;
                                });
                    }
                });

        },

    }
};
</script>
