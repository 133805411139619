<template>
<div>
    <v-dialog v-model="showAddNewOrder" v-if="showAddNewOrder" persistent>
        <ord-output-product-new @onClose="showAddNewOrder = false;
            $refs.crud.refresh();
        " v-if="showAddNewOrder"></ord-output-product-new>
    </v-dialog>

    <v-dialog v-if="dialogEdit" v-model="dialogEdit" persistent width="100%">
        <v-card>
            <ord-output-product-edit @onClose="dialogEdit = false; $refs.crud.refresh();" :value="parentID" :order="{}" :viewLoad="true"></ord-output-product-edit>
        </v-card>
    </v-dialog>

    <v-row>
        <v-col md="12" lg="12" sm="12" class="pb-1">
            <s-crud class="elevation-0 table table-hover table-condensed" no-full height="400" :excel="item.DspStatus != 3" add @add="showAddNewOrder = true" sortable :view="parentID > 0" searchInput :remove="(item.OrdStatus != 4 && item.DspStatus != 3 && item.DspStatus != 2) && parentID > 0" add-no-function delete-no-function :config="this.config" @save="save($event)" ref="crud" :filter="filter" @edit="dialogEdit = true" title="Despacho" @rowSelected="selectRows($event)">

                <template v-slot:filter>
                    <v-container>
                        <v-row justify="center">
                            <v-col lg="3" class="pt-0 pb-0" v-if="$fun.isAdmin()">
                                <s-select label="Usuario" placeholder="Digite Usuario" autofocus autocomplete clearable item-text="NtpFullName" v-model="filter.UsrID" item-value="UsrID" :items="itemsUsers"></s-select>
                            </v-col>
                            <v-col lg="2" cols="12" class="pt-0 pb-0">
                                <s-date label="Fecha Inicio" v-model="filter.BeginDate" />
                            </v-col>
                            <v-col lg="2" cols="12" class="pt-0 pb-0">
                                <s-date label="Fecha Fin" nullable v-model="filter.EndDate" />
                            </v-col>
                            <v-col lg="2" cols="12" class="pt-0">
                                <s-select-definition clearable nullable label="Estados" :def="1173" v-model="filter.DspStatus" />
                            </v-col>
                        </v-row>
                    </v-container>
                </template>

                <template v-slot:DspStatusName="{ row }">
                    <v-tooltip bottom="">
                        <template v-slot:activator="{ on }">

                            <v-icon v-on="on" :color="
                                    row.DspStatus == 1 ? 'green' : 
                                    row.DspStatus == 2 ? 'brown' : 
                                    row.DspStatus == 3 ? 'red' : 
                                    row.DspStatus == 4 ? 'blue' :  '' 
                                " style="font-size:16px;">
                                {{
                                row.DspStatus == 1 ? 'far fa-thumbs-up' : 
                                row.DspStatus == 2 ? 'fas fa-truck' : 
                                row.DspStatus == 3 ? 'far fa-thumbs-down' : 
                                row.DspStatus == 4 ? 'fa-regular fa-clock' : '' 

                            }}

                            </v-icon>
                            <a style="margin-left: 6px" @click="dialogApproval= true">{{row.DspStatusName}}</a>
                        </template>
                        <span>{{row.DspStatusName}}</span>

                    </v-tooltip>

                </template>

            </s-crud>
        </v-col>

    </v-row>

    <v-dialog v-model="dialogApproval" v-if="dialogApproval" width="60%" style="display: inline-table;">

            <ApprovalLevelsDocument :DocEntry="parentID" :TypeObject="4" />
        </v-dialog>

    <v-dialog v-if="processing" v-model="processing" width="400">
        <v-card color="primary" dark>
            <v-card-text>
                Por favor espere ...
                <v-progress-linear indeterminate color="white" class="mb-0 mt-2"></v-progress-linear>
            </v-card-text>

        </v-card>
    </v-dialog>
</div>
</template>

<script>
//Services
import _sOutputProduct from "@/services/Logistics/LgsOutputProductService";
import _sPermit from "@/services/Logistics/PermitService";


//Components
import OrdOutputProductNew from "@/views/Logistics/OrdOutput/OrdOutputProductNew";
import ApprovalLevelsDocument from "@/views/Security/Circuit/Levels/ApprovalLevelsDocument";
import OrdOutputProductEdit from '@/views/Logistics/OrdOutput/OrdOutputProductEdit.vue';

export default {
    components: {
        OrdOutputProductNew,
        ApprovalLevelsDocument,
        OrdOutputProductEdit
    },

    data: () => ({
        dialogApproval:false,
        dialogEdit:false,
        showAddNewOrder: false,
        processing: false,
        messageProcessing: "",
        //NEW ORDER
        itemsUsers: [],
        //
        item: {},

        filter: {
            BeginDate: null,
            EndDate: null,
            DspStatus: 1,
            UsrCreateID: null,
            UsrID: null
        },
        config: {
            model: {
                DspID: "ID",
                DspDate: "date",
                DspStatusName: "",
                DspStatus: "",
                SecUpdate: "date",
            },
            service: _sOutputProduct,
            headers: [{
                    text: "ID",
                    value: "DspID",
                    sortable: false
                },
                {
                    text: "Nro. Documento",
                    value: "DspDocumentNumber",
                    width: 120,
                    sortable: false
                },
                {
                    text: "Fecha",
                    value: "DspDate",
                    sortable: false
                },

                /* {
                    text: "Total despachado",
                    value: "DspTotal",
                    sortable: false,
                    width: 20,
                }, */
                {
                    text: "Usuario",
                    value: "UsrName",
                    sortable: false
                },
                {
                    text: "Usuario Despachador",
                    value: "NtpFullName",
                    sortable: false
                },
                {
                    text: "Fecha Act.",
                    value: "SecUpdate",
                    sortable: false
                },
                {
                    text: "Estado",
                    value: "DspStatusName",
                    sortable: false,
                    align: "left",
                    width: 120
                },
            ],
        },
        parentID: null,
    }),

    created() {
        _sPermit.users(this.$fun.getUserID()).then((r) => {
            this.itemsUsers = r.data;
        });
        this.filter.UsrID = this.$fun.isAdmin() ? null : this.$fun.getUserID();
    },

    methods: {

        selectRows(items) {
            if (items.length > 0) {
                this.parentID = items[0].DspID;
                this.item = items[0];
            } else {
                this.parentID = null;
                this.item = {}
            }
            console.log(this.item)
        },

    },

};
</script>
